import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import "./sideBar.css";
import { paths } from "../../../values/paths.ts";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import QrCodeScannerOutlinedIcon from "@mui/icons-material/QrCodeScannerOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import QrCodeIcon from "@mui/icons-material/QrCode";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";

interface headerDetails {
  openDrawer: any;
  commonTexts: any;
}

export default function SideBar({ openDrawer, commonTexts }: headerDetails) {
  const [currentPath, setCurrentPath] = useState("");

  const cookies = new Cookies();
  const sideBarList = [
    // {
    //     name: "Group",
    //     icon: "group.png",
    //     navigate: paths.home

    // },
    {
      name: "Profile",
      icon: "profile.png",
      navigate: paths.cards,
    },
    // {
    //     name: "Scanner",
    //     icon: "scanner.png",
    //     navigate: paths.scanner
    // },
    // {
    //   name: "Qrcode",
    //   icon: "qrCode.png",
    //   navigate: paths.qrcode,
    // },
    {
      name: "Group",
      icon: "group.png",
      navigate: paths.contact,
    },
    {
      name: "Settings",
      icon: "settings.png",
      navigate: paths.setting,
    },
  ];
  const navagtionBtnsList = [
    // {
    //   name: "Profile",
    //   icon: <PersonOutlineOutlinedIcon />,
    //   navigate: paths.cards,
    // },
    // {
    //   name: "Scan",
    //   icon: <QrCodeScannerOutlinedIcon />,
    //   navigate: paths.scanner,
    // },
    // {
    //   name: "Your QR",
    //   icon: <QrCodeIcon />,
    //   navigate: paths.qrcode,
    // },
    // {
    //   name: "Contact",
    //   icon: <PermContactCalendarOutlinedIcon />,
    //   navigate: paths.contact,
    // },
    // {
    //   name: "Settings",
    //   icon: <SettingsOutlinedIcon />,
    //   navigate: paths.setting,
    // },
  ];

  // custom Method
  const handleLogOut = () => {
    cookies.remove("user");
    cookies.remove("token");
    cookies.remove("idToken");
    // navigateTo(paths.login)
    window.location.reload();
  };

  // Life Cycle Metod

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  // UI Implamentation
  return (
    <Box className={"sideBarBox"}>
      <div className={openDrawer ? "logoBoxsidebar" : "closedlogoBoxsidebar"}>
        {openDrawer ? (
          <img
            className="img1"
            src={require("../../../Assets/Images/Icons/logo.png")}
            alt=""
          />
        ) : (
          <img
            className="img1"
            src={require("../../../Assets/Images/Icons/logo.png")}
            alt=""
          />
        )}
      </div>
      {/* <List className="optionMainBox">
        {navagtionBtnsList.map((btn, i) => (
          <ListItem key={btn.name}>
            <Button
              style={{ minWidth: openDrawer ? "100%" : "max-content" }}
              href={btn.navigate}
              key={btn.name}
              disableRipple
              className={
                currentPath === btn.navigate
                  ? "selectedPannelBtns"
                  : "pannelBtns"
              }
            >
              {btn.icon}
              {openDrawer ? btn.name : ""}
            </Button>
          </ListItem>
        ))}
      </List> */}
      <Box className="helpLogoutbox">
        {/* <Button
          disableRipple
          className={
            currentPath === "/help" ? "selectedPannelBtns" : "pannelBtns"
          }
        >
          <HelpIcon />
          {openDrawer ? commonTexts?.help : ""}
        </Button> */}
        <Button disableRipple className={"pannelBtns"} onClick={handleLogOut}>
          Logout
          <LogoutIcon />
          {openDrawer ? commonTexts?.logOut : ""}
        </Button>
      </Box>
    </Box>
  );
}

import React, { useRef, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Card,
  Paper,
  Button,
  useMediaQuery,
} from "@mui/material";
import "./addCardLayout.css";
import { commonColors } from "../../../Assets/colors.ts";
import FirstStepLayout from "../layoutSteps/firstStepLayout.tsx";
import SecondStepLayout from "../layoutSteps/secondStepLayout.tsx";
import ThirdStepLayout from "../layoutSteps/thirdStepLayout.tsx";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import links from "./socialLinks.json";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import StepperComponent from "../../commonComponents/stepper/stepper.tsx";
import CardView from "../cardView/cardView.tsx";
import { useNavigate } from "react-router"; // Import useNavigate
interface cardLayoutDetails {
  cardData?: any;
  handleCardLayout?: any;
  commonTexts?: any;
  handleProfileImage?: any;
  handleLogo?: any;
  handleLocalImage?: any;
  showAlert?: any;
  validateEmail?: any;
  tabs?: any;
  handleChange?: any;
  handleNextTab?: any;
  handleBackTab?: any;
  displayTexts?: any;
  handleCardDisplayLayout?: any;
  countryCode?: any;
  handleImage?: any;
  handleCountrycode: any;
  socialDetails: any;
  handleExpandSocialDetails: any;
  // selectedImage?: any;
}

export default function AddCardLayout({
  cardData,
  handleCardLayout,
  commonTexts,
  handleProfileImage,
  handleLogo,
  handleLocalImage,
  showAlert,
  validateEmail,
  tabs,
  handleNextTab,
  handleBackTab,
  displayTexts,
  handleCardDisplayLayout,
  countryCode,
  handleImage,
  handleCountrycode,
  socialDetails,
  handleExpandSocialDetails,
}: // selectedImage
cardLayoutDetails) {
  const navigate = useNavigate(); // Initialize navigate
  // Custome States
  const layouts = [
    { design: 1, label: "Classic" },
    { design: 2, label: "Flat" },
    { design: 3, label: "Modern" },
  ];

  const images = [
    {
      img: require("../../../Assets/Images/image/demo.jpg"),
    },
    {
      img: require("../../../Assets/Images/image/demo1.jpg"),
    },
    {
      img: require("../../../Assets/Images/image/demo2.webp"),
    },
    {
      img: require("../../../Assets/Images/image/demo3.jpg"),
    },
  ];

  const steps = ["Card Layout", "Personal Details", "Add Social"];
  const theme = [
    { colour: 1 },
    { colour: 2 },
    { colour: 3 },
    { colour: 4 },
    { colour: 5 },
    { colour: 6 },
    { colour: 7 },
    { colour: 8 },
  ];

  const [preview, setPreview] = useState(false);
  const profileLayout = [{ profile: 1 }];
  const logoLayout = [{ logo: 1 }];
  let windowHeight = "170";
  const parentRef = useRef<any>(null);

  // This will true when window screen will be lesser than 890px.
  const tabletMobileView = useMediaQuery("(max-width:890px)");

  // Custome Methods

  const handleColor = (value: any, faded?: any) => {
    if (value === 1) {
      return faded
        ? commonColors.dynemicFadedLightPurple
        : commonColors.dynemicLightPurple;
    } else if (value === 2) {
      return faded ? commonColors.fadedMainColor : commonColors.MainColor;
    } else if (value === 3) {
      return faded ? commonColors.dynamicFadedRed : commonColors.dynamicRed;
    } else if (value === 4) {
      return faded ? commonColors.dynamicFadedGreen : commonColors.dynamicGreen;
    } else if (value === 5) {
      return faded
        ? commonColors.dynamicFadedPurple
        : commonColors.dynamicPurple;
    } else if (value === 6) {
      return faded
        ? commonColors.dynamicFadedOrange
        : commonColors.dynamicOrange;
    } else if (value === 7) {
      return faded
        ? commonColors.dynamicFadedYellow
        : commonColors.dynamicYellow;
    } else {
      return faded ? commonColors.dynamicFadedBlue : commonColors.dynamicBlue;
    }
  };

  const handlePreview = () => {
    setPreview(!preview);
  };

  const handlePaperHeight = () => {
    if (parentRef?.current?.offsetHeight !== null) {
      windowHeight = parentRef?.current?.offsetHeight;
    }
  };

  // Event Method
  window.addEventListener("resize", handlePaperHeight);

  // UI Implamentation
  return (
    <Box ref={parentRef} className="addCardLayoutBox">
      <div className="stepperMainBox">
        <StepperComponent currentstep={tabs} steps={steps} />
      </div>
      <Box className="LayoutMainBox">
        <Paper
          className="mainLayoutPaper"
          elevation={0}
          square
          sx={{ height: `calc(99vh - ${windowHeight}px)` }}
        >
          <div className="mainPaperLayoutSub">
            <div className="layoutHeader">
              <div className="purpleDiv" />
              <Typography className="layoutHeadingText">
                {tabs === 0
                  ? commonTexts?.layout
                  : tabs === 1
                  ? commonTexts?.yourDetails
                  : commonTexts?.yourSocials}
              </Typography>
            </div>
            {tabs === 0 && (
              <FirstStepLayout
                layouts={layouts}
                cardData={cardData}
                handleColor={handleColor}
                handleCardLayout={handleCardLayout}
                handleLocalImage={handleLocalImage}
                theme={theme}
                images={images}
                profileLayout={profileLayout}
                handleLogo={handleLogo}
                handleProfileImage={handleProfileImage}
                logoLayout={logoLayout}
                handleImage={handleImage}
                commonTexts={commonTexts}
                windowHeight={windowHeight}
              />
            )}
            {tabs === 1 && (
              <SecondStepLayout
                showAlert={showAlert}
                cardData={cardData}
                commonTexts={commonTexts}
                handleCardLayout={handleCardLayout}
                validateEmail={validateEmail}
                handleCountrycode={handleCountrycode}
                countryCode={countryCode}
                windowHeight={windowHeight}
              />
            )}
            {tabs === 2 && (
              <ThirdStepLayout
                displayTexts={displayTexts}
                cardData={cardData}
                handleCardDisplayLayout={handleCardDisplayLayout}
                handleCardLayout={handleCardLayout}
                validateEmail={validateEmail}
                socialDetails={socialDetails}
                handleExpandSocialDetails={handleExpandSocialDetails}
                commonTexts={commonTexts}
                showAlert={showAlert}
              />
            )}
            <div className="layoutfooter">
              {tabs === 0 ? (
                <Button
                  className="backLayoutBtn"
                  onClick={() => window.location.reload()} // Reload the page when tabs === 0
                >
                  {/* <img
                    src={require("../../../Assets/Images/Icons/back.png")}
                    alt=""
                  /> */}
                  {commonTexts?.back}
                </Button>
              ) : (
                <Button className="backLayoutBtn" onClick={handleBackTab}>
                  {/* <img
                    src={require("../../../Assets/Images/Icons/back.png")}
                    alt=""
                  /> */}
                  {commonTexts?.back}
                </Button>
              )}
              <Button
                //  disabled={tabs !== 0 ? !validation() : false}
                //  className={validation() ? "nextLayoutBtn" : tabs === 0 ? "nextLayoutBtn" : 'disabledNexyBtn'}
                className={"nextLayoutBtn"}
                onClick={handleNextTab}
              >
                {tabs === 2 ? commonTexts.save : commonTexts?.next}
                {/* <EastIcon /> */}
                {tabs === 2 ? (
                  ""
                ) : (
                  <img
                    src={require("../../../Assets/Images/Icons/next.png")}
                    alt=""
                  />
                )}
              </Button>
            </div>
          </div>
        </Paper>
        <CardView
          tabletMobileView={tabletMobileView}
          preview={preview}
          handlePreview={handlePreview}
          handleColor={handleColor}
          cardData={cardData}
          countryCode={countryCode}
          displayTexts={displayTexts}
          commonTexts={commonTexts}
          links={links}
          dashBoard={true}
        />
      </Box>
    </Box>
  );
}

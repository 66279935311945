// import { Button } from "@mui/material";
// import React from "react";
// import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
// import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner"; // Import the scan icon

// import { commonColors } from "../../../Assets/colors.ts";

// interface Props {
//   handleSearch?: any;
//   handleOpen?: any;
//   buttonName?: any;
//   placeHolder?: any;
//   handleScanClick?: any; // Add this prop to handle the scan action
// }

// function AddSearchBar({
//   handleSearch,
//   handleOpen,
//   buttonName,
//   placeHolder,
//   handleScanClick, // Destructure the prop here
// }: Props) {
//   return (
//     <div
//       style={{
//         width: "100%",
//         display: "flex",
//         justifyContent: "space-evenly",
//         alignItems: "center",
//         marginTop: 16,
//       }}
//     >
//       {/* Optional Search Field */}
//       {/* <TextField
//           style={{ flexGrow: 1 }}
//           placeholder={placeHolder}
//           onChange={handleSearch}
//         /> */}

//       {/* "Add" Button */}
//       <Button
//         variant="contained"
//         color="primary"
//         onClick={handleOpen}
//         startIcon={<AddCircleOutlineRoundedIcon />}
//       >
//         {buttonName}
//       </Button>

//       {/* "Scan" Button */}
//       <Button
//         variant="contained"
//         color="primary"
//         onClick={handleScanClick}
//         startIcon={<QrCodeScannerIcon />} // Add the scan icon
//       >
//         Scan
//       </Button>
//     </div>
//   );
// }

// export default AddSearchBar;
// import React, { useState } from "react";
// import {
//   Button,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
//   TextField,
//   MenuItem,
//   Grid,
//   Avatar,
//   IconButton,
//   Stepper,
//   Step,
//   StepLabel,
// } from "@mui/material";
// import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
// import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
// import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

// interface Props {
//   handleSearch?: any;
//   buttonName?: string;
//   placeHolder?: string;
//   handleScanClick?: () => void;
// }

// const AddSearchBar: React.FC<Props> = ({ buttonName, handleScanClick }) => {
//   const [open, setOpen] = useState(false);
//   const [profileImage, setProfileImage] = useState<string | null>(null);
//   const [activeStep, setActiveStep] = useState(0);

//   // Form Data
//   const [formData, setFormData] = useState({
//     prefix: "",
//     firstName: "",
//     middleName: "",
//     lastName: "",
//     suffix: "",
//     accreditation: "",
//     preferredName: "",
//     maidenName: "",
//     pronounces: "",
//     title: "",
//     department: "",
//     company: "",
//     headline: "",
//     instagram: "",
//     linkedin: "",
//     twitter: "",
//     facebook: "",
//     github: "",
//     website: "",
//     youtube: "",
//     email: "",
//     phone: "",
//     other: "",
//   });

//   // Handle form input changes
//   const handleChange = (
//     e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
//   ) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   // Handle image upload
//   const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
//     if (event.target.files && event.target.files[0]) {
//       const file = event.target.files[0];
//       const reader = new FileReader();
//       reader.onload = () => {
//         setProfileImage(reader.result as string);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   // Handle navigation between steps
//   const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
//   const handleBack = () => setActiveStep((prevStep) => prevStep - 1);
//   const handleSubmit = () => {
//     console.log("Profile Image:", profileImage);
//     console.log("Form Data:", formData);
//     setOpen(false);
//     setActiveStep(0);
//   };

//   return (
//     <div
//       style={{
//         width: "100%",
//         display: "flex",
//         justifyContent: "space-evenly",
//         alignItems: "center",
//         marginTop: 16,
//       }}
//     >
//       {/* Add Button */}
//       <Button
//         variant="contained"
//         color="primary"
//         startIcon={<AddCircleOutlineRoundedIcon />}
//         onClick={() => setOpen(true)}
//       >
//         {buttonName || "Add"}
//       </Button>

//       {/* Scan Button */}
//       <Button
//         variant="contained"
//         color="primary"
//         onClick={handleScanClick}
//         startIcon={<QrCodeScannerIcon />}
//       >
//         Scan
//       </Button>

//       {/* Add Form Dialog */}
//       <Dialog
//         open={open}
//         onClose={() => setOpen(false)}
//         fullWidth
//         maxWidth="sm"
//       >
//         <DialogTitle>Add New Person</DialogTitle>
//         <DialogContent>
//           {/* Stepper */}
//           <Stepper activeStep={activeStep} alternativeLabel>
//             <Step>
//               <StepLabel>Personal Details</StepLabel>
//             </Step>
//             <Step>
//               <StepLabel>Social & Contact</StepLabel>
//             </Step>
//           </Stepper>

//           {/* Personal Details Form */}
//           {activeStep === 0 ? (
//             <Grid container spacing={2} justifyContent="center">
//               {/* Profile Picture Upload */}
//               <Grid item xs={12} sx={{ textAlign: "center" }}>
//                 <Avatar
//                   src={profileImage || "https://via.placeholder.com/150"}
//                   alt="Profile Picture"
//                   sx={{ width: 100, height: 100, margin: "auto" }}
//                 />
//                 <input
//                   accept="image/*"
//                   type="file"
//                   id="profile-upload"
//                   style={{ display: "none" }}
//                   onChange={handleImageUpload}
//                 />
//                 <label htmlFor="profile-upload">
//                   <IconButton component="span" color="primary">
//                     <PhotoCameraIcon />
//                   </IconButton>
//                 </label>
//               </Grid>

//               {/* Prefix & First Name */}
//               <Grid item xs={6}>
//                 <TextField
//                   select
//                   fullWidth
//                   label="Prefix"
//                   name="prefix"
//                   value={formData.prefix}
//                   onChange={handleChange}
//                 >
//                   <MenuItem value="Mr.">Mr.</MenuItem>
//                   <MenuItem value="Mrs.">Mrs.</MenuItem>
//                   <MenuItem value="Ms.">Ms.</MenuItem>
//                   <MenuItem value="Dr.">Dr.</MenuItem>
//                 </TextField>
//               </Grid>
//               <Grid item xs={6}>
//                 <TextField
//                   fullWidth
//                   label="First Name"
//                   name="firstName"
//                   value={formData.firstName}
//                   onChange={handleChange}
//                 />
//               </Grid>

//               {/* Middle & Last Name */}
//               <Grid item xs={6}>
//                 <TextField
//                   fullWidth
//                   label="Middle Name"
//                   name="middleName"
//                   value={formData.middleName}
//                   onChange={handleChange}
//                 />
//               </Grid>
//               <Grid item xs={6}>
//                 <TextField
//                   fullWidth
//                   label="Last Name"
//                   name="lastName"
//                   value={formData.lastName}
//                   onChange={handleChange}
//                 />
//               </Grid>

//               {/* Suffix, Accreditation, Preferred Name */}
//               <Grid item xs={4}>
//                 <TextField
//                   fullWidth
//                   label="Suffix"
//                   name="suffix"
//                   value={formData.suffix}
//                   onChange={handleChange}
//                 />
//               </Grid>
//               <Grid item xs={4}>
//                 <TextField
//                   fullWidth
//                   label="Accreditation"
//                   name="accreditation"
//                   value={formData.accreditation}
//                   onChange={handleChange}
//                 />
//               </Grid>
//               <Grid item xs={4}>
//                 <TextField
//                   fullWidth
//                   label="Preferred Name"
//                   name="preferredName"
//                   value={formData.preferredName}
//                   onChange={handleChange}
//                 />
//               </Grid>

//               {/* Maiden Name, Pronounces, Title */}
//               <Grid item xs={4}>
//                 <TextField
//                   fullWidth
//                   label="Maiden Name"
//                   name="maidenName"
//                   value={formData.maidenName}
//                   onChange={handleChange}
//                 />
//               </Grid>
//               <Grid item xs={4}>
//                 <TextField
//                   fullWidth
//                   label="Pronounces"
//                   name="pronounces"
//                   value={formData.pronounces}
//                   onChange={handleChange}
//                 />
//               </Grid>
//               <Grid item xs={4}>
//                 <TextField
//                   fullWidth
//                   label="Title"
//                   name="title"
//                   value={formData.title}
//                   onChange={handleChange}
//                 />
//               </Grid>

//               {/* Department, Company, Headline */}
//               <Grid item xs={4}>
//                 <TextField
//                   fullWidth
//                   label="Department"
//                   name="department"
//                   value={formData.department}
//                   onChange={handleChange}
//                 />
//               </Grid>
//               <Grid item xs={4}>
//                 <TextField
//                   fullWidth
//                   label="Company"
//                   name="company"
//                   value={formData.company}
//                   onChange={handleChange}
//                 />
//               </Grid>
//               <Grid item xs={4}>
//                 <TextField
//                   fullWidth
//                   label="Headline"
//                   name="headline"
//                   value={formData.headline}
//                   onChange={handleChange}
//                 />
//               </Grid>
//             </Grid>
//           ) : (
//             <Grid container spacing={2}>
//               {/* Social Media & Contact Links */}
//               {[
//                 "instagram",
//                 "linkedin",
//                 "twitter",
//                 "facebook",
//                 "github",
//                 "website",
//                 "youtube",
//                 "email",
//                 "phone",
//                 "other",
//               ].map((field) => (
//                 <Grid item xs={12} key={field}>
//                   <TextField
//                     fullWidth
//                     label={field.charAt(0).toUpperCase() + field.slice(1)}
//                     name={field}
//                     value={(formData as any)[field]}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//               ))}
//             </Grid>
//           )}
//         </DialogContent>

//         {/* Navigation Buttons */}
//         <DialogActions>
//           {activeStep > 0 && <Button onClick={handleBack}>Back</Button>}
//           {activeStep === 0 ? (
//             <Button onClick={handleNext}>Next</Button>
//           ) : (
//             <Button variant="contained" color="primary" onClick={handleSubmit}>
//               Save
//             </Button>
//           )}
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default AddSearchBar;
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Grid,
  Avatar,
  IconButton,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Cookies from "universal-cookie";

interface Props {
  getResponseData?: any;
  handleOpen?: any;
  buttonName?: string;
  placeHolder?: string;
  handleClose?: () => void; //-------------------------------Gagan---------------------
  EditOpen?: any; //-------------------------------Gagan---------------------
  setEditOpen?: any; //-------------------------------Gagan---------------------
  setEditData?: any; //-------------------------------Gagan---------------------
  UpdatacontactData?: any; //-------------------------------Gagan---------------------
  handleSubmitUpdataData?: () => void; //-------------------------------Gagan---------------------
  handleScanClick?: () => void;
}
const API_BASE_URL =
  "https://2wlbswocq3.execute-api.eu-west-1.amazonaws.com/prod";
const AddSearchBar: React.FC<Props> = ({
  getResponseData,
  handleOpen,
  setEditData, //-------------------------------Gagan---------------------
  setEditOpen, //-------------------------------Gagan---------------------
  UpdatacontactData, //-------------------------------Gagan---------------------
  handleSubmitUpdataData, //-------------------------------Gagan---------------------
  EditOpen, //-------------------------------Gagan---------------------
  handleClose, //-------------------------------Gagan---------------------
  buttonName,
  handleScanClick,
}) => {
  const cookies = new Cookies();
  const token = cookies.get("idToken");
  const [open, setOpen] = useState(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  // New state variables
  const [contactCard, setContactCard] = useState<any[]>([]); // You can adjust the type as needed
  const [showAlert, setShowAlert] = useState(false); // For showing error alerts

  // Form Data
  const [formData, setFormData] = useState({
    prefix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    // suffix: "",
    // accreditation: "",
    // preferredName: "",
    // maidenName: "",
    // pronounces: "",
    // title: "",
    // department: "",
    company: "",
    phone: "",
    email: "",
    address: "",
    instagram: "",
    linkedin: "",
    twitter: "",
    facebook: "",

    youtube: "",
    other: "",
    // github: "",
    // website: "",
  });

  useEffect(() => {
    setOpen(EditOpen);
    setFormData(UpdatacontactData || {});
    console.log("Open -------------> ", open);
    console.log("formData in useeffect -------------> ", formData);
    console.log("contactdata in useeffect -------------> ", UpdatacontactData);
  }, [EditOpen, UpdatacontactData]);

  // Handle form input changes
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setEditData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle image upload
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handle navigation between steps
  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  // Submit the form data
  const handleSubmit = async () => {
    try {
      // API call to submit the contact
      const response = await fetch(`${API_BASE_URL}/contacts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(formData), // Include the form data in the request
      });

      const responseData = await response.json(); // Get the response data

      if (response.status === 200) {
        // On successful submission, update the contact card and reset form
        setContactCard([...contactCard, responseData]); // Assuming responseData is the new contact
        setOpen(false); // Close the dialog
        setShowAlert(false); // Hide the alert
        setFormData({
          prefix: "",
          firstName: "",
          middleName: "",
          lastName: "",
          // suffix: "",
          // accreditation: "",
          // preferredName: "",
          // maidenName: "",
          // pronounces: "",
          // title: "",
          // department: "",
          email: "",
          phone: "",
          company: "",
          address: "",
          instagram: "",
          linkedin: "",
          twitter: "",
          facebook: "",
          // github: "",
          // website: "",
          youtube: "",
          other: "",
        });
        // Reset the form data
      }
      getResponseData();
    } catch (error) {
      console.error("Error submitting contact:", error);
      setShowAlert(true); // Show an error alert if the submission fails
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        marginTop: 16,
      }}
    >
      {/* Add Button */}
      <Button
        sx={{
          fontWeight: "bold",
          fontFamily: "'Roboto', sans-serif",
          background: "linear-gradient(180deg, #be6639, #494445)",
        }}
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutlineRoundedIcon />}
        onClick={() => setOpen(true)}
      >
        {buttonName || "Add"}
      </Button>

      {/* Scan Button */}
      <Button
        sx={{
          background: "linear-gradient(180deg, #be6639, #494445)",
          fontWeight: "bold",
          fontFamily: "'Roboto', sans-serif",
        }}
        variant="contained"
        color="primary"
        onClick={handleScanClick}
        startIcon={<QrCodeScannerIcon />}
      >
        Scan
      </Button>

      {/* Add Form Dialog */}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setEditOpen(false);
          handleClose();
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle> New Contact</DialogTitle>
        <DialogContent>
          {/* Stepper */}
          <Stepper activeStep={activeStep} alternativeLabel>
            <Step>
              <StepLabel>Personal Details</StepLabel>
            </Step>
            <Step>
              <StepLabel>Social & Contact</StepLabel>
            </Step>
          </Stepper>

          {/* Personal Details Form */}
          {activeStep === 0 ? (
            <Grid container spacing={1} justifyContent="center">
              {/* Profile Picture Upload */}
              {/* <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Avatar
                  src={profileImage || "https://via.placeholder.com/150"}
                  alt="Profile Picture"
                  sx={{ width: 100, height: 100, margin: "auto" }}
                />
                <input
                  accept="image/*"
                  type="file"
                  id="profile-upload"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                <label htmlFor="profile-upload">
                  <IconButton component="span" color="primary">
                    <PhotoCameraIcon />
                  </IconButton>
                </label>
              </Grid> */}

              {/* Prefix & First Name */}
              <Grid item xs={6}>
                <TextField
                  select
                  fullWidth
                  label="Prefix"
                  name="prefix"
                  value={formData.prefix}
                  onChange={handleChange}
                >
                  <MenuItem value="Mr.">Mr.</MenuItem>
                  <MenuItem value="Mrs.">Mrs.</MenuItem>
                  <MenuItem value="Ms.">Ms.</MenuItem>
                  <MenuItem value="Dr.">Dr.</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </Grid>

              {/* Middle & Last Name */}
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  label="Middle Name"
                  name="middleName"
                  value={formData.middleName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </Grid>

              {/* Suffix, Accreditation, Preferred Name */}
              {/* <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Suffix"
                  name="suffix"
                  value={formData.suffix}
                  onChange={handleChange}
                />
              </Grid> */}
              {/* <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Accreditation"
                  name="accreditation"
                  value={formData.accreditation}
                  onChange={handleChange}
                />
              </Grid> */}
              {/* <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Preferred Name"
                  name="preferredName"
                  value={formData.preferredName}
                  onChange={handleChange}
                />
              </Grid> */}

              {/* Maiden Name, Pronounces, Title */}
              {/* <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Maiden Name"
                  name="maidenName"
                  value={formData.maidenName}
                  onChange={handleChange}
                />
              </Grid> */}
              {/* <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Pronounces"
                  name="pronounces"
                  value={formData.pronounces}
                  onChange={handleChange}
                />
              </Grid> */}
              {/* <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </Grid> */}

              {/* Department, Company, Headline */}
              {/* <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Department"
                  name="department"
                  value={formData.department}
                  onChange={handleChange}
                />
              </Grid> */}
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  label="Company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  label="Phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  label="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={1}>
              {/* Social Media & Contact Links */}
              {/* <Grid item xs={10}>
                <TextField
                  fullWidth
                  label="Instagram"
                  name="instagram"
                  value={formData.instagram}
                  onChange={handleChange}
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="LinkedIn"
                  name="linkedin"
                  value={formData.linkedin}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Twitter"
                  name="twitter"
                  value={formData.twitter}
                  onChange={handleChange}
                />
              </Grid>

              {/* Facebook, GitHub, Website */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Facebook"
                  name="facebook"
                  value={formData.facebook}
                  onChange={handleChange}
                />
              </Grid>
              {/* <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="GitHub"
                  name="github"
                  value={formData.github}
                  onChange={handleChange}
                />
              </Grid> */}
              {/* <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Website"
                  name="website"
                  value={formData.website}
                  onChange={handleChange}
                />
              </Grid> */}

              {/* YouTube, Email, Phone */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="YouTube"
                  name="youtube"
                  value={formData.youtube}
                  onChange={handleChange}
                />
              </Grid>

              {/* Other Link */}
              {/* <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Other"
                  name="other"
                  value={formData.other}
                  onChange={handleChange}
                />
              </Grid> */}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          {/* Navigation between steps */}
          {activeStep > 0 && <Button onClick={handleBack}>Back</Button>}
          {activeStep < 1 ? (
            <Button onClick={handleNext}>Next</Button>
          ) : (
            <Button onClick={EditOpen ? handleSubmitUpdataData : handleSubmit}>
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddSearchBar;

import {
  Box,
  Divider,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { commonColors } from "../../Assets/colors.ts";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BusinessIcon from "@mui/icons-material/Business";
import PlaceIcon from "@mui/icons-material/Place";
import {
  AccountCircle,
  Phone,
  Email,
  Title,
  Work,
  MoreVert,
} from "@mui/icons-material"; // Example icons
import "./contactCards.css";

interface props {
  contactCard?: any;
  // handleContactEdit?: any;
  showSearchData?: any;
  handleContactEdit: (item: any, index: number) => void;
  handledeleteContact: (index: number) => void;
  searchData?: any;
}

function ContactCards({
  contactCard,
  // handleContactEdit,
  showSearchData,
  handleContactEdit,
  handledeleteContact,
  searchData,
}: props) {
  // const showData = searchData ? showSearchData : contactCard;
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuData, setMenuData] = useState(null);
  console.log(`showSearchData`, showSearchData);
  console.log(`contactCard`, contactCard);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    clickedItem: any
  ) => {
    // console.log("Menu clicked for item:", clickedItem); // Logs the clicked item's data
    setMenuData(clickedItem); // Save the clicked item's data
    setAnchorEl(event.currentTarget); // Open the menu
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const isMenuOpen = Boolean(anchorEl);
  // useEffect(() => {
  //   if (isMenuOpen && menuData) {
  //     console.log("Menu Opened - Values:");
  //     console.log("Email:", menuData?.email || "Not Provided");
  //     console.log("Address:", menuData?.address || "Not Provided");
  //     console.log("Company:", menuData?.company || "Not Provided");
  //   }
  // }, [isMenuOpen, menuData]);
  return (
    <Box
      className="c-cardMainBox"
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 2,
        justifyContent: "center",
        padding: "20px",
        backgroundColor: "#f4f6f8",
      }}
    >
      {(showSearchData?.length > 0 ? showSearchData : contactCard)?.map(
        /* -------------------------------Gagan Mangal---------------------------------------------- */
        (item: any, index: any) => (
          <Box
            className="c-MainCard"
            sx={{
              borderRadius: "16px",
              boxShadow: "0px 6px 15px rgb(239, 11, 11)",
              overflow: "hidden",
              backgroundColor: "#fff",
              transition: "transform 0.3s",
              "&:hover": {
                transform: "scale(1.03)",
              },
            }}
            key={index}
          >
            <Box sx={{ position: "relative", padding: "0px" }}>
              <div
                className="c-cardImgBox"
                style={{
                  backgroundColor:
                    item.theme === 6
                      ? commonColors.AccentColor
                      : item.theme === 5
                      ? commonColors.dynamicPurple
                      : item.theme === 4
                      ? commonColors.dynamicBlue
                      : item.theme === 3
                      ? commonColors.dynamicGreen
                      : item.theme === 2
                      ? commonColors.dynamicOrange
                      : commonColors.MainColor,
                  width: "70%",
                  height: "auto",
                  borderRadius: "12px",
                  marginBottom: "16px",
                }}
              ></div>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "600",
                  padding: "10px 10px",
                  lineHeight: "25px",
                  marginBottom: "8px",
                  borderTop: "1px solidrgb(248, 9, 9)",
                  borderBottom: "1px solidrgb(235, 14, 14) 56, 56)",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "-20px",
                    right: "1px", // Delete icon ko right pe rakha hai
                    cursor: "pointer",
                    color: "#e1aa86",
                    borderRadius: "10%",
                    padding: "5px",
                    transition: "all 0.3s",
                    "&:hover": { background: "white" },
                  }}
                  onClick={() => handledeleteContact(index)} // Index ke jagah contactId pass karo
                >
                  <DeleteForeverIcon
                    sx={{
                      "&:hover": { color: "#6f6f78" },
                      fontSize: "2rem",
                      padding: "1px",
                    }}
                  />
                </Box>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    gap: "5px",
                  }}
                >
                  <AccountCircle style={{ fontSize: "1.5rem", color: "red" }} />
                  <span style={{ color: "#555", fontSize: "0.7rem" }}>
                    {item.prefix || ""}
                  </span>

                  {/* <AccountCircle style={{ fontSize: "1rem", color: "purple" }} /> */}
                  <span style={{ color: "#333", fontSize: "0.8rem" }}>
                    {item.firstName || " "}
                  </span>
                  <span style={{ color: "#333", fontSize: "0.8rem" }}>
                    {item.middleName || ""}
                  </span>
                  <span style={{ color: "#333", fontSize: "0.8rem" }}>
                    {item.lastName || ""}
                  </span>
                </div>

                {/* Suffix with icon */}
                <h3
                  style={{
                    color: "#555",
                    fontSize: "0.7rem",
                    margin: "0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <BusinessIcon
                    style={{
                      fontSize: "1.5rem",
                      color: "blue",
                      marginRight: "5px",
                    }}
                  />
                  {item.company || "Company"}
                </h3>

                {/* Phone with icon */}
                <p
                  style={{
                    color: "#555",
                    fontSize: "0.7rem",
                    margin: "0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Phone
                    style={{
                      fontSize: "1.5rem",
                      color: "green",
                      marginRight: "5px",
                    }}
                  />
                  {item.phone || "Phone"}
                </p>
                <p
                  style={{
                    color: "#555",
                    fontSize: "0.7rem",
                    margin: "0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Email
                    style={{
                      fontSize: "1.5rem",
                      color: "#e1aa86",
                      marginRight: "5px",
                    }}
                  />
                  {item.email || "Email"}
                </p>
                <p
                  style={{
                    color: "#555",
                    fontSize: "0.7rem",
                    margin: "0px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PlaceIcon
                    style={{
                      fontSize: "1.5rem",
                      color: "blue",
                      marginRight: "5px",
                    }}
                  />
                  {item.address || "Address"}
                </p>
              </Typography>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: "10px",
                    gap: "15px",
                    flexWrap: "wrap",
                  }}
                >
                  {item.facebook && (
                    <a
                      href={`https://facebook.com/${item.facebook}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../Assets/Images/Icons/faceBookColored.png")}
                        alt="Facebook"
                        style={{ width: "18px", height: "18px" }}
                      />
                    </a>
                  )}
                  {item.instagram && (
                    <a
                      href={`https://instagram.com/${item.instagram}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../Assets/Images/Icons/instaColored.png")}
                        alt="Instagram"
                        style={{ width: "18px", height: "18px" }}
                      />
                    </a>
                  )}
                  {item.twitter && (
                    <a
                      href={`https://twitter.com/${item.twitter}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../Assets/Images/Icons/twitterColored.png")}
                        alt="Twitter"
                        style={{ width: "18px", height: "18px" }}
                      />
                    </a>
                  )}
                  {item.linkedin && (
                    <a
                      href={`https://linkedin.com/in/${item.linkedin}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../Assets/Images/Icons/linkedinColored.png")}
                        alt="LinkedIn"
                        style={{ width: "18px", height: "18px" }}
                      />
                    </a>
                  )}
                  {item.youtube && (
                    <a
                      href={`https://youtube.com/${item.youtube}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={require("../../Assets/Images/Icons/youtubeColored.png")}
                        alt="YouTube"
                        style={{ width: "18px", height: "18px" }}
                      />
                    </a>
                  )}
                  <Box
                    sx={{
                      position: "absolute",
                      left: "5px", // Edit icon ko right pe shift kiya hai
                      cursor: "pointer",
                      bottom: "-5px",
                      color: "#e1aa86",
                      borderRadius: "100%",
                      padding: "5px",
                      transition: "all 0.3s",
                      "&:hover": { background: "while" },
                    }}
                    onClick={() => handleContactEdit(item, index)} // Function call sahi karo
                  >
                    <EditRoundedIcon
                      sx={{
                        "&:hover": {
                          color: "#6f6f78",
                          backgroundColor: "white",
                        },
                        fontSize: "1.7rem",
                        padding: "1px",
                      }}
                    />
                  </Box>
                </Box>
              </div>
            </Box>
          </Box>
        )
      )}
    </Box>
  );
}

export default ContactCards;

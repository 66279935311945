import React, { useEffect, useState } from "react";
import "./loginForm.css";
import {
  Box,
  Button,
  Divider,
  Typography,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  Link,
} from "@mui/material";
import InputField from "../commonComponents/InputField/inputField.tsx";
import { validateEmail } from "../../utils/commonRegex.ts";
import service from "../../services/service.ts";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router";
import { paths } from "../../values/paths.ts";

interface props {
  commonTexts?: any;
  setIsLogedIn?: any;
}

function LoginForm({ commonTexts, setIsLogedIn }: props) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const maxW900px = useMediaQuery("(max-width: 900px)");
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  //  Custom Methods
  const handleLoginData = (keyName: any) => (e: any) => {
    setLoginData({ ...loginData, [keyName]: e.target.value });
  };

  // life Cycle Method
  useEffect(() => {
    if (validateEmail(loginData.email)) {
      return setError(false);
    } else {
      return setError(true);
    }
  }, [loginData.email]);

  //  Api Call
  const onClickLogin = async () => {
    if (loginData.email?.length == 0 || loginData.password.length == 0) {
      return setShowAlert(true);
    } else {
      setShowAlert(false);
      let data = {
        email: loginData.email,
        password: loginData.password,
      };
      const response = await service.login(data);
      if (response) {
        cookies.set("token", response?.accessToken, { path: "/" });
        cookies.set("idToken", response?.idToken, { path: "/" });
        setIsLogedIn(cookies.get("token"));
        setTimeout(() => {
          navigate(paths.cards);
        }, 1000);
      }
    }
  };
  return (
    <Box className="loginpage">
      {!maxW900px && (
        <Box className="loginLogoBox">
          <img
            src={require("../../Assets/Images/Icons/logo.png")}
            className="loginPageLogo"
          />
          <img
            src={require("../../Assets/Images/image/loginImg.png")}
            className="loginPageImg"
          />
        </Box>
      )}
      <Box className="loginRightBox">
        <Box className="loginRightSubBox">
          {maxW900px && (
            <Box className="loginLogoBox">
              <img
                src={require("../../Assets/Images/Icons/logo.png")}
                className="loginPageLogo"
              />
            </Box>
          )}
          <div>
            <Typography className="loginBoxHeadingText">{"Login"}</Typography>
          </div>
          <div>
            <Typography className="loginInputHeading">
              {" "}
              {commonTexts?.email}{" "}
            </Typography>
            <InputField
              value={loginData.email}
              onChange={handleLoginData("email")}
              sru={{
                colors: "red ",
              }}
              type="email"
              // label={loginData.email ? "" : commonTexts?.mailCom}
            />
            {showAlert && loginData.email?.length == 0 && (
              <Typography className="alertText">
                {" "}
                {commonTexts?.enterEmail}
              </Typography>
            )}
            {error && loginData.email?.length > 0 && (
              <Typography className="alertText">
                {" "}
                {commonTexts?.emailFormat}{" "}
              </Typography>
            )}
          </div>
          <div className="passwordInput">
            <Typography className="loginInputHeading">
              {" "}
              {commonTexts?.password}{" "}
            </Typography>
            <InputField
              value={loginData.password}
              onChange={handleLoginData("password")}
              type="password"
              // label={loginData.password ? "" : "•••••••••"}
            />
            {showAlert && loginData.password?.length == 0 && (
              <Typography className="alertText">
                {" "}
                {commonTexts?.enterYourPassword}
              </Typography>
            )}
            <div className="passwordDetailBox">
              <FormControlLabel
                label={
                  <Typography className="checkBoxLabel">
                    {commonTexts?.rememberMe}
                  </Typography>
                }
                control={
                  <Checkbox
                    size="small"
                    checked={rememberMe}
                    sx={{
                      "&.Mui-checked": {
                        color: "black",
                      },
                    }}
                  />
                }
              />
              <Link
                underline="none"
                onClick={() => navigate(paths.forgotpassword)}
                className="forgotPasswordText"
                style={{ cursor: "pointer" }}
              >
                <Button disableRipple className="forgotBtn">
                  {commonTexts?.ForgotPassword}
                </Button>
              </Link>
            </div>
          </div>
          <Box className="loginBtnBox">
            <Button type="submit" className="loginBtn" onClick={onClickLogin}>
              {commonTexts?.login}
            </Button>
          </Box>
          <Divider className="dividerText" variant="middle">
            {commonTexts?.Or}
          </Divider>
          <Typography className="dontaccText">
            {commonTexts?.dontHaveAccount}
            <Link
              underline="none"
              onClick={() => navigate(paths.signup)}
              className="signupText"
              style={{ cursor: "pointer" }}
            >
              {commonTexts?.Signup}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default LoginForm;

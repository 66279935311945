// import React, { useEffect, useState } from "react";
// import SideBar from "../../Components/commonComponents/sideBar/sideBar.tsx";
// import { Box, useMediaQuery } from "@mui/material";
// import AddSearchBar from "../../Components/commonComponents/addSearchBar/addSearchBar.tsx";
// import AddContact from "../../Components/contactComponents/addContactDrawer/addContact.tsx";
// import {
//   OnlyNumbers,
//   validateEmail,
//   validateUrl,
// } from "../../utils/commonRegex.ts";
// import texts from "../../Assets/CommonTexts/texts.json";
// import ContactCards from "../../Components/contactComponents/contactCards.tsx";
// import HeaderBar from "../../Components/commonComponents/headerBar/headerBar.tsx";
// import { Button } from "@mui/material";
// import ScannerPage from "../scannerPage/scannerPage.tsx";
// function ContactPage() {
//   const [activeButton, setActiveButton] = useState("add"); // "add" or "scan"
//   const [open, setOpen] = useState(false);
//   const [contactCard, setContactCard] = useState<any>([]);
//   const [contactData, setContactdata] = useState({
//     profileImg: "",
//     theme: 1,
//     prefix: "",
//     firstName: "",
//     middleName: "",
//     lastName: "",
//     suffix: "",
//     accreditations: "",
//     prefferredName: "",
//     maidenName: "",
//     pronouse: "",
//     title: "",
//     department: "",
//     company: "",
//     headline: "",
//     moreInfo: [] as any,
//   });
//   const [isLoading, setIsLoading] = useState(false);
//   const [showAlert, setShowAlert] = useState(false);
//   const [showSubAlert, setShowSubAlert] = useState(false);
//   const [edit, setEdit] = useState(false);
//   const [selectCard, setSelectCard] = useState<any>();
//   const [error, setError] = useState(false);
//   const [searchData, setSearchData] = useState("");
//   const [showSearchData, sewtShowSearchData] = useState(contactCard);
//   let check = [] as any;
//   const [openDrawer, setOpenDrawer] = useState(false);
//   const maxWidth890px = useMediaQuery("(max-width:890px)");
//   const [addNewCard, setAddNewCard] = useState(false);

//   const handleDrawerOpen = () => {
//     return setAddNewCard(true);
//   };

//   const handleDrawer = (value: any) => {
//     setOpenDrawer(value);
//   };
//   const [addMoreInfo, setAddMoreInfo] = useState({
//     mainInput: "",
//     subInput: "",
//   });

//   const handleContactData = (keyName: any, e: any) => {
//     if (keyName === "profileImg") {
//       setContactdata({
//         ...contactData,
//         [keyName]: URL.createObjectURL(e.target.files[0]),
//       });
//     } else if (keyName === "theme") {
//       setContactdata({ ...contactData, [keyName]: e });
//     } else {
//       setContactdata({ ...contactData, [keyName]: e.target.value });
//     }
//   };

//   const filterData = contactData.moreInfo.filter(
//     (item: any, i: any) =>
//       i ===
//       contactData.moreInfo.findIndex((items: any) => items.label === item.label)
//   );

//   const handleAddMoreInfo = (e: any) => {
//     setIsLoading(!isLoading);
//     const data = {
//       id: e.id,
//       logo: e.logo,
//       label: e.label,
//       firstInput: "",
//       subInput: "",
//       verfiyUrl: false,
//     };
//     contactData.moreInfo.push(data);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     check = [];
//     setContactdata({
//       ...contactData,
//       profileImg: "",
//       theme: 1,
//       prefix: "",
//       firstName: "",
//       middleName: "",
//       lastName: "",
//       suffix: "",
//       accreditations: "",
//       prefferredName: "",
//       maidenName: "",
//       pronouse: "",
//       title: "",
//       department: "",
//       company: "",
//       headline: "",
//       moreInfo: [] as any,
//     });
//   };

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   const handleSubmitData = () => {
//     if (edit === true) {
//       setIsLoading(!isLoading);
//       let data = contactCard[selectCard];
//       if (contactData) {
//         for (let key in contactData) {
//           data[key] = contactData[key];
//         }
//       }
//       if (
//         contactData.profileImg.length &&
//         contactData.prefix.length &&
//         contactData.firstName.length &&
//         contactData.lastName.length &&
//         contactData.middleName.length &&
//         contactData.suffix.length &&
//         contactData.accreditations.length &&
//         contactData.prefferredName.length &&
//         contactData.maidenName.length &&
//         contactData.pronouse.length &&
//         contactData.title.length &&
//         contactData.department.length &&
//         contactData.company.length &&
//         contactData.headline.length
//       ) {
//         if (filterData.length > 0) {
//           for (let index = 0; index < filterData.length; index++) {
//             if (
//               filterData[index].firstInput.length > 0 &&
//               filterData[index].subInput.length > 0
//             ) {
//               check.push(true);
//             }
//           }
//           if (check.length === filterData.length) {
//             contactCard[selectCard] = data;
//             setOpen(false);
//             setShowSubAlert(false);
//             setEdit(false);
//           } else {
//             setShowSubAlert(true);
//           }
//         } else {
//           contactCard[selectCard] = data;
//           setOpen(false);
//           setEdit(false);
//           setShowAlert(false);
//           setContactdata({
//             ...contactData,
//             profileImg: "",
//             theme: 1,
//             prefix: "",
//             firstName: "",
//             middleName: "",
//             lastName: "",
//             suffix: "",
//             accreditations: "",
//             prefferredName: "",
//             maidenName: "",
//             pronouse: "",
//             title: "",
//             department: "",
//             company: "",
//             headline: "",
//             moreInfo: [] as any,
//           });
//         }
//         setShowAlert(false);
//         setContactdata({
//           ...contactData,
//           profileImg: "",
//           theme: 1,
//           prefix: "",
//           firstName: "",
//           middleName: "",
//           lastName: "",
//           suffix: "",
//           accreditations: "",
//           prefferredName: "",
//           maidenName: "",
//           pronouse: "",
//           title: "",
//           department: "",
//           company: "",
//           headline: "",
//           moreInfo: [] as any,
//         });
//       } else {
//         setShowAlert(true);
//       }
//     } else {
//       setIsLoading(!isLoading);
//       if (
//         contactData.profileImg.length &&
//         contactData.prefix.length &&
//         contactData.firstName.length &&
//         contactData.lastName.length &&
//         contactData.middleName.length &&
//         contactData.suffix.length &&
//         contactData.accreditations.length &&
//         contactData.prefferredName.length &&
//         contactData.maidenName.length &&
//         contactData.pronouse.length &&
//         contactData.title.length &&
//         contactData.department.length &&
//         contactData.company.length &&
//         contactData.headline.length
//       ) {
//         if (filterData.length > 0) {
//           for (let index = 0; index < filterData.length; index++) {
//             if (
//               filterData[index].firstInput.length > 0 &&
//               filterData[index].subInput.length > 0
//             ) {
//               check.push(true);
//             }
//           }
//           if (check.length === filterData.length) {
//             contactCard.push(contactData);
//             setOpen(false);
//             setShowSubAlert(false);
//             setContactdata({
//               ...contactData,
//               profileImg: "",
//               theme: 1,
//               prefix: "",
//               firstName: "",
//               middleName: "",
//               lastName: "",
//               suffix: "",
//               accreditations: "",
//               prefferredName: "",
//               maidenName: "",
//               pronouse: "",
//               title: "",
//               department: "",
//               company: "",
//               headline: "",
//               moreInfo: [] as any,
//             });
//           } else {
//             setShowSubAlert(true);
//           }
//         } else {
//           contactCard.push(contactData);
//           setOpen(false);
//           setContactdata({
//             ...contactData,
//             profileImg: "",
//             theme: 1,
//             prefix: "",
//             firstName: "",
//             middleName: "",
//             lastName: "",
//             suffix: "",
//             accreditations: "",
//             prefferredName: "",
//             maidenName: "",
//             pronouse: "",
//             title: "",
//             department: "",
//             company: "",
//             headline: "",
//             moreInfo: [] as any,
//           });
//         }
//         setShowAlert(false);
//       } else {
//         setShowAlert(true);
//       }
//     }
//   };

//   const handleRemove = (e: any) => {
//     const remove = filterData.filter((item: any) => item.label !== e.label);
//     setContactdata({ ...contactData, moreInfo: remove });
//     setShowSubAlert(false);
//   };

//   const handleAddMoreInput = (keyName: any, e: any, index: any) => {
//     setAddMoreInfo({ ...addMoreInfo, [keyName]: e.target.value });
//     if (keyName === "mainInput") {
//       filterData[index].firstInput = e.target.value;
//     } else if (keyName === "subInput") {
//       filterData[index].subInput = e.target.value;
//     }
//   };
//   const handleContactEdit = (item: any, index: any) => {
//     setOpen(true);
//     setContactdata({
//       ...contactData,
//       profileImg: item.profileImg,
//       theme: item.theme,
//       prefix: item.prefix,
//       firstName: item.firstName,
//       middleName: item.middleName,
//       lastName: item.lastName,
//       suffix: item.suffix,
//       accreditations: item.accreditations,
//       prefferredName: item.prefferredName,
//       maidenName: item.maidenName,
//       pronouse: item.pronouse,
//       title: item.title,
//       department: item.department,
//       company: item.company,
//       headline: item.headline,
//       moreInfo: item.moreInfo,
//     });
//     setEdit(true);
//     setSelectCard(index);
//   };

//   const handleSearch = (e: any) => {
//     setSearchData(e.target.value);
//     const filterdItem = contactCard.filter((user: any) =>
//       user?.firstName?.toLowerCase().includes(searchData?.toLowerCase())
//     );
//     sewtShowSearchData(filterdItem);
//   };

//   useEffect(() => {
//     setIsLoading(!isLoading);
//     for (let index = 0; index < filterData?.length; index++) {
//       const element =
//         filterData[index].label === "Phone"
//           ? OnlyNumbers(filterData[index].firstInput)
//           : filterData[index].label === "Email"
//           ? validateEmail(filterData[index].firstInput)
//           : validateUrl(filterData[index].firstInput);
//       filterData[index].verfiyUrl = element;
//       if (element) {
//         setError(true);
//       } else {
//         setError(false);
//       }
//     }
//   }, [addMoreInfo]);

//   const [showScanner, setShowScanner] = useState(false);

//   const handleScanClick = () => {
//     setShowScanner(true); // Show the ScannerPage component
//   };

//   const handleBackClick = () => {
//     setShowScanner(false); // Return to ContactPage
//   };
//   return (
//     <Box className="mainLoginBox">
//       {!maxWidth890px && (
//         <SideBar commonTexts={texts.commonText} openDrawer={openDrawer} />
//       )}
//       <Box className="layout">
//         <HeaderBar
//           openAddCards={handleDrawerOpen}
//           openDrawer={openDrawer}
//           handleDrawer={handleDrawer}
//           searchInput={searchData}
//           commonText={texts.commonText}
//         />

//         {/* Conditionally render AddSearchBar */}
//         {!showScanner && (
//           <AddSearchBar
//             handleSearch={handleSearch}
//             handleOpen={handleOpen}
//             placeHolder="Search Contacts"
//             buttonName="Add"
//             handleScanClick={handleScanClick}
//           />
//         )}

//         {/* Conditionally render the ScannerPage and Back Button */}
//         <Box>
//           {showScanner ? (
//             <Box>
//               <Button
//                 variant="outlined"
//                 color="secondary"
//                 onClick={handleBackClick}
//                 style={{ marginBottom: "20px" }}
//               >
//                 Back
//               </Button>
//               <ScannerPage />
//             </Box>
//           ) : (
//             <Box>
//               {/* This will render when showScanner is false */}
//               <ContactCards
//                 contactCard={contactCard}
//                 handleContactEdit={handleContactEdit}
//                 showSearchData={showSearchData}
//                 searchData={searchData}
//               />
//             </Box>
//           )}
//         </Box>

//         {/* Render AddContact as usual */}
//         <AddContact
//           commonText={texts}
//           handleClose={handleClose}
//           open={open}
//           showAlert={showAlert}
//           contactData={contactData}
//           handleSubmitData={handleSubmitData}
//           handleContactData={handleContactData}
//           handleAddMoreInfo={handleAddMoreInfo}
//           handleRemove={handleRemove}
//           filterData={filterData}
//           handleAddMoreInput={handleAddMoreInput}
//           showSubAlert={showSubAlert}
//           addMoreInfo={addMoreInfo}
//           error={error}
//         />
//       </Box>
//     </Box>
//   );
// }

// export default ContactPage;

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// import React, { useEffect, useState } from "react";
// import axios from "axios"; // Importing axios
// import SideBar from "../../Components/commonComponents/sideBar/sideBar.tsx";
// import { Box, useMediaQuery } from "@mui/material";
// import AddSearchBar from "../../Components/commonComponents/addSearchBar/addSearchBar.tsx";
// import AddContact from "../../Components/contactComponents/addContactDrawer/addContact.tsx";
// import {
//   OnlyNumbers,
//   validateEmail,
//   validateUrl,
// } from "../../utils/commonRegex.ts";
// import texts from "../../Assets/CommonTexts/texts.json";
// import ContactCards from "../../Components/contactComponents/contactCards.tsx";
// import HeaderBar from "../../Components/commonComponents/headerBar/headerBar.tsx";
// import { Button } from "@mui/material";
// import ScannerPage from "../scannerPage/scannerPage.tsx";

// // API Base URL
// const API_BASE_URL =
//   "https://2wlbswocq3.execute-api.eu-west-1.amazonaws.com/prod"; // Replace with your API endpoint

// function ContactPage() {
//   const [activeButton, setActiveButton] = useState("add");
//   const [open, setOpen] = useState(false);
//   const [contactCard, setContactCard] = useState<any>([]);
//   const [contactData, setContactdata] = useState({
//     profileImg: "",
//     theme: 1,
//     prefix: "",
//     firstName: "",
//     middleName: "",
//     lastName: "",
//     suffix: "",
//     accreditations: "",
//     prefferredName: "",
//     maidenName: "",
//     pronouse: "",
//     title: "",
//     department: "",
//     company: "",
//     headline: "",
//     moreInfo: [] as any,
//   });
//   const [isLoading, setIsLoading] = useState(false);
//   const [showAlert, setShowAlert] = useState(false);
//   const [showSubAlert, setShowSubAlert] = useState(false);
//   const [edit, setEdit] = useState(false);
//   const [selectCard, setSelectCard] = useState<any>();
//   const [error, setError] = useState(false);
//   const [searchData, setSearchData] = useState("");
//   const [showSearchData, sewtShowSearchData] = useState(contactCard);
//   let check = [] as any;
//   const [openDrawer, setOpenDrawer] = useState(false);
//   const maxWidth890px = useMediaQuery("(max-width:890px)");
//   const [addNewCard, setAddNewCard] = useState(false);
//   const [addMoreInfo, setAddMoreInfo] = useState({
//     mainInput: "",
//     subInput: "",
//   });

//   const handleDrawerOpen = () => setAddNewCard(true);
//   const handleDrawer = (value: any) => setOpenDrawer(value);

//   const handleContactData = (keyName: any, e: any) => {
//     if (keyName === "profileImg") {
//       setContactdata({
//         ...contactData,
//         [keyName]: URL.createObjectURL(e.target.files[0]),
//       });
//     } else if (keyName === "theme") {
//       setContactdata({ ...contactData, [keyName]: e });
//     } else {
//       setContactdata({ ...contactData, [keyName]: e.target.value });
//     }
//   };

//   const filterData = contactData.moreInfo.filter(
//     (item: any, i: any) =>
//       i ===
//       contactData.moreInfo.findIndex((items: any) => items.label === item.label)
//   );

//   const handleAddMoreInfo = (e: any) => {
//     setIsLoading(!isLoading);
//     const data = {
//       id: e.id,
//       logo: e.logo,
//       label: e.label,
//       firstInput: "",
//       subInput: "",
//       verfiyUrl: false,
//     };
//     contactData.moreInfo.push(data);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     check = [];
//     setContactdata({
//       ...contactData,
//       profileImg: "",
//       theme: 1,
//       prefix: "",
//       firstName: "",
//       middleName: "",
//       lastName: "",
//       suffix: "",
//       accreditations: "",
//       prefferredName: "",
//       maidenName: "",
//       pronouse: "",
//       title: "",
//       department: "",
//       company: "",
//       headline: "",
//       moreInfo: [] as any,
//     });
//   };

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   //----------------------------------------------------------------------------------------------------

//   const handleRemove = (e: any) => {
//     const remove = filterData.filter((item: any) => item.label !== e.label);
//     setContactdata({ ...contactData, moreInfo: remove });
//     setShowSubAlert(false);
//   };

//   const handleAddMoreInput = (keyName: any, e: any, index: any) => {
//     setAddMoreInfo({ ...addMoreInfo, [keyName]: e.target.value });
//     if (keyName === "mainInput") {
//       filterData[index].firstInput = e.target.value;
//     } else if (keyName === "subInput") {
//       filterData[index].subInput = e.target.value;
//     }
//   };
//   // Fetch contacts data from API
//   const fetchContacts = async () => {
//     try {
//       const response = await axios.get(`${API_BASE_URL}/contacts`);
//       setContactCard(response.data);
//     } catch (error) {
//       console.error("Error fetching contacts:", error);
//     }
//   };

//   // Submit contact data to API
//   const submitContact = async (data: any) => {
//     try {
//       const response = await axios.post(`${API_BASE_URL}/contact`, data);
//       if (response.status === 201) {
//         setContactCard([...contactCard, response.data]);
//         setOpen(false);
//         setShowAlert(false);
//         setContactdata({
//           profileImg: "",
//           theme: 1,
//           prefix: "",
//           firstName: "",
//           middleName: "",
//           lastName: "",
//           suffix: "",
//           accreditations: "",
//           prefferredName: "",
//           maidenName: "",
//           pronouse: "",
//           title: "",
//           department: "",
//           company: "",
//           headline: "",
//           moreInfo: [],
//         });
//       }
//     } catch (error) {
//       console.error("Error submitting contact:", error);
//       setShowAlert(true);
//     }
//   };

//   // Edit contact data (API call to update the contact)
//   const updateContact = async (id: string, data: any) => {
//     try {
//       const response = await axios.put(`${API_BASE_URL}/contact/${id}`, data);
//       if (response.status === 200) {
//         const updatedContacts = contactCard.map((contact: any) =>
//           contact.id === id ? { ...contact, ...data } : contact
//         );
//         setContactCard(updatedContacts);
//         setOpen(false);
//         setEdit(false);
//       }
//     } catch (error) {
//       console.error("Error updating contact:", error);
//       setShowAlert(true);
//     }
//   };
//   const handleSearch = (e: any) => {
//     setSearchData(e.target.value);
//     const filterdItem = contactCard.filter((user: any) =>
//       user?.firstName?.toLowerCase().includes(searchData?.toLowerCase())
//     );
//     sewtShowSearchData(filterdItem);
//   };

//   const handleSubmitData = () => {
//     if (edit) {
//       const selectedContact = contactCard[selectCard];
//       updateContact(selectedContact.id, contactData);
//     } else {
//       submitContact(contactData);
//     }
//   };

//   useEffect(() => {
//     fetchContacts();
//   }, []);
//   const [showScanner, setShowScanner] = useState(false);

//   const handleScanClick = () => {
//     setShowScanner(true); // Show the ScannerPage component
//   };

//   const handleBackClick = () => {
//     setShowScanner(false); // Return to ContactPage
//   };
//   const handleContactEdit = (item: any, index: any) => {
//     setOpen(true);
//     setContactdata({
//       ...contactData,
//       profileImg: item.profileImg,
//       theme: item.theme,
//       prefix: item.prefix,
//       firstName: item.firstName,
//       middleName: item.middleName,
//       lastName: item.lastName,
//       suffix: item.suffix,
//       accreditations: item.accreditations,
//       prefferredName: item.prefferredName,
//       maidenName: item.maidenName,
//       pronouse: item.pronouse,
//       title: item.title,
//       department: item.department,
//       company: item.company,
//       headline: item.headline,
//       moreInfo: item.moreInfo,
//     });
//     setEdit(true);
//     setSelectCard(index);
//   };

//   return (
//     <Box className="mainLoginBox">
//       {!maxWidth890px && (
//         <SideBar commonTexts={texts.commonText} openDrawer={openDrawer} />
//       )}
//       <Box className="layout">
//         <HeaderBar
//           openAddCards={handleDrawerOpen}
//           openDrawer={openDrawer}
//           handleDrawer={handleDrawer}
//           searchInput={searchData}
//           commonText={texts.commonText}
//         />
//         {!showScanner && (
//           <AddSearchBar
//             handleSearch={handleSearch}
//             handleOpen={handleOpen}
//             placeHolder="Search Contacts"
//             buttonName="Add"
//             handleScanClick={handleScanClick}
//           />
//         )}
//         <Box>
//           {showScanner ? (
//             <Box>
//               <Button
//                 variant="outlined"
//                 color="secondary"
//                 onClick={handleBackClick}
//                 style={{ marginBottom: "20px" }}
//               >
//                 Back
//               </Button>
//               <ScannerPage />
//             </Box>
//           ) : (
//             <Box>
//               <ContactCards
//                 contactCard={contactCard}
//                 handleContactEdit={handleContactEdit}
//                 showSearchData={showSearchData}
//                 searchData={searchData}
//               />
//             </Box>
//           )}
//         </Box>
//         <AddContact
//           commonText={texts}
//           handleClose={handleClose}
//           open={open}
//           showAlert={showAlert}
//           contactData={contactData}
//           handleSubmitData={handleSubmitData}
//           handleContactData={handleContactData}
//           handleAddMoreInfo={handleAddMoreInfo}
//           handleRemove={handleRemove}
//           filterData={filterData}
//           handleAddMoreInput={handleAddMoreInput}
//           showSubAlert={showSubAlert}
//           addMoreInfo={addMoreInfo}
//           error={error}
//         />
//       </Box>
//     </Box>
//   );
// }

// export default ContactPage;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { Box, useMediaQuery } from "@mui/material";
// import { useCookies } from "react-cookie"; // Importing useCookies hook
// import SideBar from "../../Components/commonComponents/sideBar/sideBar.tsx";
// import AddSearchBar from "../../Components/commonComponents/addSearchBar/addSearchBar.tsx";
// import { Typography } from "@mui/material";

// import AddContact from "../../Components/contactComponents/addContactDrawer/addContact.tsx";
// import {
//   OnlyNumbers,
//   validateEmail,
//   validateUrl,
// } from "../../utils/commonRegex.ts";
// import texts from "../../Assets/CommonTexts/texts.json";
// import ContactCards from "../../Components/contactComponents/contactCards.tsx";
// import HeaderBar from "../../Components/commonComponents/headerBar/headerBar.tsx";
// import { Button } from "@mui/material";
// import ScannerPage from "../scannerPage/scannerPage.tsx";
// import Cookies from "universal-cookie";

// // API Base URL
// const API_BASE_URL =
//   "https://2wlbswocq3.execute-api.eu-west-1.amazonaws.com/prod"; // Replace with your API endpoint

// function ContactPage() {
//   const cookies = new Cookies();
//   const token = cookies.get("idToken");
//   const [activeButton, setActiveButton] = useState("add");
//   const [open, setOpen] = useState(false);
//   const [contactCard, setContactCard] = useState<any>([]);
//   const [contactData, setContactdata] = useState({
//     profileImg: "",
//     theme: 1,
//     prefix: "",
//     firstName: "",
//     middleName: "",
//     lastName: "",
//     suffix: "",
//     accreditations: "",
//     prefferredName: "",
//     maidenName: "",
//     pronouse: "",
//     title: "",
//     department: "",
//     company: "",
//     headline: "",
//     moreInfo: [] as any,
//   });
//   const [isLoading, setIsLoading] = useState(false);
//   const [showAlert, setShowAlert] = useState(false);
//   const [showSubAlert, setShowSubAlert] = useState(false);
//   const [edit, setEdit] = useState(false);
//   const [selectCard, setSelectCard] = useState<any>();
//   const [error, setError] = useState(false);
//   const [searchData, setSearchData] = useState("");
//   const [showSearchData, sewtShowSearchData] = useState(contactCard);
//   let check = [] as any;
//   const [openDrawer, setOpenDrawer] = useState(false);
//   const maxWidth890px = useMediaQuery("(max-width:890px)");
//   const [addNewCard, setAddNewCard] = useState(false);
//   const [addMoreInfo, setAddMoreInfo] = useState({
//     mainInput: "",
//     subInput: "",
//   });

//   const handleDrawerOpen = () => setAddNewCard(true);
//   const handleDrawer = (value: any) => setOpenDrawer(value);

//   const handleContactData = (keyName: any, e: any) => {
//     if (keyName === "profileImg") {
//       setContactdata({
//         ...contactData,
//         [keyName]: URL.createObjectURL(e.target.files[0]),
//       });
//     } else if (keyName === "theme") {
//       setContactdata({ ...contactData, [keyName]: e });
//     } else {
//       setContactdata({ ...contactData, [keyName]: e.target.value });
//     }
//   };

//   const filterData =
//     contactData.moreInfo && Array.isArray(contactData.moreInfo)
//       ? contactData.moreInfo.filter(
//           (item: any, i: any) =>
//             i ===
//             contactData.moreInfo.findIndex(
//               (items: any) => items.label === item.label
//             )
//         )
//       : [];

//   // const handleAddMoreInfo = (e: any) => {
//   //   setIsLoading(!isLoading);
//   //   const data = {
//   //     id: e.id,
//   //     logo: e.logo,
//   //     label: e.label,
//   //     firstInput: "",
//   //     subInput: "",
//   //     verfiyUrl: false,
//   //   };
//   //   contactData.moreInfo.push(data);
//   // };

//   const handleAddMoreInfo = (e: any) => {
//     setIsLoading((prev) => !prev);

//     const data = {
//       id: e.id,
//       logo: e.logo || null, // Ensure logo is set to null if undefined
//       label: e.label || "Unknown", // Provide a default label
//       firstInput: "",
//       subInput: "",
//       verifyUrl: false, // Corrected spelling from "verfiyUrl" to "verifyUrl"
//     };

//     // Ensure moreInfo exists and is an array
//     if (!Array.isArray(contactData.moreInfo)) {
//       contactData.moreInfo = []; // Initialize as an empty array if it doesn't exist
//     }

//     // Push the new data directly into contactData.moreInfo
//     contactData.moreInfo.push(data);

//     setIsLoading((prev) => !prev); // Toggle loading back
//   };

//   const handleClose = () => {
//     setOpen(false);
//     check = [];
//     setContactdata({
//       ...contactData,
//       profileImg: "",
//       theme: 1,
//       prefix: "",
//       firstName: "",
//       middleName: "",
//       lastName: "",
//       suffix: "",
//       accreditations: "",
//       prefferredName: "",
//       maidenName: "",
//       pronouse: "",
//       title: "",
//       department: "",
//       company: "",
//       headline: "",
//       moreInfo: [] as any,
//     });
//   };

//   const handleOpen = () => {
//     setOpen(true);
//   };

//   //----------------------------------------------------------------------------------------------------

//   const handleRemove = (e: any) => {
//     const remove = filterData.filter((item: any) => item.label !== e.label);
//     setContactdata({ ...contactData, moreInfo: remove });
//     setShowSubAlert(false);
//   };

//   const handleAddMoreInput = (keyName: any, e: any, index: any) => {
//     setAddMoreInfo({ ...addMoreInfo, [keyName]: e.target.value });
//     if (keyName === "mainInput") {
//       filterData[index].firstInput = e.target.value;
//     } else if (keyName === "subInput") {
//       filterData[index].subInput = e.target.value;
//     }
//   };

//   // Fetch contacts data from API
//   // const fetchContacts = async () => {
//   //   try {
//   //     // First API Call: Fetch basic contact list
//   //     const response = await fetch(`${API_BASE_URL}/contacts`, {
//   //       method: "GET",
//   //       headers: {
//   //         "Content-Type": "application/json",
//   //         Authorization: `${token}`, // Ensure correct format
//   //       },
//   //     });

//   //     if (!response.ok) {
//   //       throw new Error("Failed to fetch contacts");
//   //     }

//   //     const contactList = await response.json(); // Assuming response contains contactId
//   //     console.log("Contact List:", contactList);

//   //     // Extract contact IDs
//   //     const contactIds = contactList.map((contact) => contact.contactId);
//   //     const detailsPromises = contactIds.map((contactId) =>
//   //       fetch(`${API_BASE_URL}/contacts/${contactId}`, {
//   //         method: "GET",
//   //         headers: {
//   //           "Content-Type": "application/json",
//   //           Authorization: `${token}`, // Ensure correct format
//   //         },
//   //       }).then((res) => res.json())
//   //     );

//   //     const contactDetails = await Promise.all(detailsPromises);
//   //     console.log(`contactDetails`, contactDetails);
//   //     const combinedData = contactList.map((contact, index) => ({
//   //       ...contact,
//   //       details: contactDetails[index],
//   //     }));

//   //     console.log("Combined Data:", combinedData);
//   //     setContactCard(combinedData);
//   //   } catch (error) {
//   //     console.error("Error fetching contacts:", error);
//   //     setError(error.message);
//   //   }
//   // };
//   const fetchContacts = async () => {
//     try {
//       const response = await fetch(`${API_BASE_URL}/contacts`, {
//         method: "GET",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `${token}`,
//         },
//       });

//       if (!response.ok) {
//         throw new Error("Failed to fetch contacts");
//       }

//       const contactList = await response.json();

//       // Check if there are no contacts
//       if (!Array.isArray(contactList) || contactList.length === 0) {
//         console.log("No contacts found.");
//         setContactCard([]); // Set empty array
//         return; // Exit function early
//       }

//       console.log("Contact List:", contactList);

//       // Extract contact IDs
//       const contactIds = contactList.map((contact) => contact.contactId);
//       const detailsPromises = contactIds.map((contactId) =>
//         fetch(`${API_BASE_URL}/contacts/${contactId}`, {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `${token}`,
//           },
//         }).then((res) => res.json())
//       );

//       const contactDetails = await Promise.all(detailsPromises);
//       const combinedData = contactList.map((contact, index) => ({
//         ...contact,
//         details: contactDetails[index],
//       }));

//       console.log("Combined Data:", combinedData);
//       setContactCard(combinedData);
//     } catch (error) {
//       console.error("Error fetching contacts:", error);
//       setError(error.message);
//     }
//   };

//   // Submit contact data to API
//   // const submitContact = async (data: any) => {
//   //   try {
//   //     const response = await fetch(`${API_BASE_URL}/contacts`, {
//   //       method: "POST",
//   //       headers: {
//   //         "Content-Type": "application/json",
//   //         Authorization: `${token}`,
//   //       },
//   //       body: JSON.stringify(data), // ✅ Include the contact data in the request
//   //     });

//   //     const responseData = await response.json(); // ✅ Store the response correctly
//   //     console.log(responseData);
//   //     if (response.status === 200) {
//   //       setContactCard([...contactCard, response]);
//   //       setOpen(false);
//   //       setShowAlert(false);
//   //       setContactdata({
//   //         profileImg: "",
//   //         theme: 1,
//   //         prefix: "",
//   //         firstName: "",
//   //         middleName: "",
//   //         lastName: "",
//   //         suffix: "",
//   //         accreditations: "",
//   //         prefferredName: "",
//   //         maidenName: "",
//   //         pronouse: "",
//   //         title: "",
//   //         department: "",
//   //         company: "",
//   //         headline: "",
//   //         moreInfo: [],
//   //       });
//   //     }
//   //   } catch (error) {
//   //     console.error("Error submitting contact:", error);
//   //     setShowAlert(true);
//   //   }
//   // };

//   // Edit contact data (API call to update the contact)
//   const updateContact = async (id: string, data: any) => {
//     try {
//       const response = await axios.put(`${API_BASE_URL}/contact/${id}`, data, {
//         headers: { Authorization: `Bearer ${token}` }, // Including token in the request header
//       });
//       if (response.status === 200) {
//         const updatedContacts = contactCard.map((contact: any) =>
//           contact.id === id ? { ...contact, ...data } : contact
//         );
//         setContactCard(updatedContacts);
//         setOpen(false);
//         setEdit(false);
//       }
//     } catch (error) {
//       console.error("Error updating contact:", error);
//       setShowAlert(true);
//     }
//   };

//   const handleSearch = (e: any) => {
//     setSearchData(e.target.value);
//     const filterdItem = contactCard.filter((user: any) =>
//       user?.firstName?.toLowerCase().includes(searchData?.toLowerCase())
//     );
//     sewtShowSearchData(filterdItem);
//   };

//   const handleSubmitData = () => {
//     if (edit) {
//       const selectedContact = contactCard[selectCard];
//       updateContact(selectedContact.id, contactData);
//     } else {
//       submitContact(contactData);
//     }
//   };

//   useEffect(() => {
//     fetchContacts();
//   }, [token]); // Refresh data when token is updated

//   const [showScanner, setShowScanner] = useState(false);

//   const handleScanClick = () => {
//     setShowScanner(true); // Show the ScannerPage component
//   };

//   const handleBackClick = () => {
//     setShowScanner(false); // Return to ContactPage
//   };

//   const handleContactEdit = (item: any, index: any) => {
//     setOpen(true);
//     setContactdata({
//       ...contactData,
//       profileImg: item.profileImg,
//       theme: item.theme,
//       prefix: item.prefix,
//       firstName: item.firstName,
//       middleName: item.middleName,
//       lastName: item.lastName,
//       suffix: item.suffix,
//       accreditations: item.accreditations,
//       prefferredName: item.prefferredName,
//       maidenName: item.maidenName,
//       pronouse: item.pronouse,
//       title: item.title,
//       department: item.department,
//       company: item.company,
//       headline: item.headline,
//       moreInfo: item.moreInfo,
//     });
//     setEdit(true);
//     setSelectCard(index);
//   };

//   return (
//     <Box className="mainLoginBox">
//       {/* {!maxWidth890px && (
//         <SideBar commonTexts={texts.commonText} openDrawer={openDrawer} />
//       )} */}
//       <Box className="layout">
//         {/* <HeaderBar
//           openAddCards={handleDrawerOpen}
//           openDrawer={openDrawer}
//           handleDrawer={handleDrawer}
//           searchInput={searchData}
//           commonText={texts.commonText}
//         /> */}
//         {!showScanner && (
//           <AddSearchBar
//             // handleSearch={handleSearch}
//             handleOpen={handleOpen}
//             // placeHolder="Search Contacts"
//             buttonName="Add"
//             handleScanClick={handleScanClick}
//           />
//         )}
//         <Box>
//           {showScanner ? (
//             <Box>
//               <Button
//                 variant="outlined"
//                 color="secondary"
//                 onClick={handleBackClick}
//                 style={{ marginBottom: "20px" }}
//               >
//                 Back
//               </Button>
//               <ScannerPage />
//             </Box>
//           ) : contactCard.length === 0 ? (
//             // ✅ Render the empty card UI when no contacts exist
//             <div className="emptycardBox">
//               <img
//                 src={require("../../Assets/Images/image/emptyCardsImg.png")}
//                 alt=""
//                 className="emptycardImg"
//               />
//               <Typography className="emptycardText">
//                 {"No cards found"}
//               </Typography>
//             </div>
//           ) : (
//             <Box>
//               <ContactCards
//                 contactCard={contactCard}
//                 handleContactEdit={handleContactEdit}
//                 showSearchData={showSearchData}
//                 searchData={searchData}
//               />
//             </Box>
//           )}
//         </Box>

//         <AddContact
//           commonText={texts}
//           handleClose={handleClose}
//           open={open}
//           showAlert={showAlert}
//           contactData={contactData}
//           handleSubmitData={handleSubmitData}
//           handleContactData={handleContactData}
//           handleAddMoreInfo={handleAddMoreInfo}
//           handleRemove={handleRemove}
//           filterData={filterData}
//           handleAddMoreInput={handleAddMoreInput}
//           showSubAlert={showSubAlert}
//           addMoreInfo={addMoreInfo}
//           error={error}
//         />
//       </Box>
//     </Box>
//   );
// }

// export default ContactPage;
//----------------------------------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, useMediaQuery } from "@mui/material";
import { useCookies } from "react-cookie"; // Importing useCookies hook
import SideBar from "../../Components/commonComponents/sideBar/sideBar.tsx";
import AddSearchBar from "../../Components/commonComponents/addSearchBar/addSearchBar.tsx";
import { Typography } from "@mui/material";

import AddContact from "../../Components/contactComponents/addContactDrawer/addContact.tsx";
import {
  OnlyNumbers,
  validateEmail,
  validateUrl,
} from "../../utils/commonRegex.ts";
import texts from "../../Assets/CommonTexts/texts.json";
import ContactCards from "../../Components/contactComponents/contactCards.tsx";
import HeaderBar from "../../Components/commonComponents/headerBar/headerBar.tsx";
import { Button } from "@mui/material";
import ScannerPage from "../scannerPage/scannerPage.tsx";
import Cookies from "universal-cookie";

// API Base URL
const API_BASE_URL =
  "https://2wlbswocq3.execute-api.eu-west-1.amazonaws.com/prod"; // Replace with your API endpoint

function ContactPage({
  setContactCardData,
  showSearchDatacontactCardData,
  searchData,
}) {
  const cookies = new Cookies();
  const token = cookies.get("idToken");
  const [activeButton, setActiveButton] = useState("add");
  const [open, setOpen] = useState(false);
  const [EditOpen, setEditOpen] = useState(false);
  const [EditData, setEditData] = useState({});
  const [UpdatacontactData, setUpdataContactData] = useState({});
  const [contactCard, setContactCard] = useState<any>([]);
  const [contactData, setContactdata] = useState({
    // profileImg: "",
    // theme: 1,
    prefix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    // suffix: "",
    // accreditations: "",
    // prefferredName: "",
    // maidenName: "",
    // pronouse: "",
    // title: "",
    // department: "",
    company: "",
    phone: "",
    email: "",
    address: "",
    instagram: "",
    linkedin: "",
    twitter: "",
    facebook: "",
    youtube: "",
    // other: "",
    // moreInfo: [] as any,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [ContactIndex, setContactIndex] = useState<number | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showSubAlert, setShowSubAlert] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectCard, setSelectCard] = useState<any>();
  const [error, setError] = useState(false);
  // const [searchData, setSearchData] = useState("");
  const [showSearchData, setShowSearchData] = useState(contactCard);
  let check = [] as any;
  const [openDrawer, setOpenDrawer] = useState(false);
  const maxWidth890px = useMediaQuery("(max-width:890px)");
  const [addNewCard, setAddNewCard] = useState(false);
  const [addMoreInfo, setAddMoreInfo] = useState({
    mainInput: "",
    subInput: "",
  });

  const handleDrawerOpen = () => setAddNewCard(true);
  const handleDrawer = (value: any) => setOpenDrawer(value);

  const handleContactData = (keyName: any, e: any) => {
    if (keyName === "profileImg") {
      setContactdata({
        ...contactData,
        [keyName]: URL.createObjectURL(e.target.files[0]),
      });
    } else if (keyName === "theme") {
      setContactdata({ ...contactData, [keyName]: e });
    } else {
      setContactdata({ ...contactData, [keyName]: e.target.value });
    }
  };

  // const filterData =
  //   contactData.moreInfo && Array.isArray(contactData.moreInfo)
  //     ? contactData.moreInfo.filter(
  //         (item: any, i: any) =>
  //           i ===
  //           contactData.moreInfo.findIndex(
  //             (items: any) => items.label === item.label
  //           )
  //       )
  //     : [];

  // const handleAddMoreInfo = (e: any) => {
  //   setIsLoading(!isLoading);
  //   const data = {
  //     id: e.id,
  //     logo: e.logo,
  //     label: e.label,
  //     firstInput: "",
  //     subInput: "",
  //     verfiyUrl: false,
  //   };
  //   contactData.moreInfo.push(data);
  // };

  const handleAddMoreInfo = (e: any) => {
    setIsLoading((prev) => !prev);

    const data = {
      id: e.id,
      logo: e.logo || null, // Ensure logo is set to null if undefined
      label: e.label || "Unknown", // Provide a default label
      firstInput: "",
      subInput: "",
      verifyUrl: false, // Corrected spelling from "verfiyUrl" to "verifyUrl"
    };

    // Ensure moreInfo exists and is an array
    // if (!Array.isArray(contactData.moreInfo)) {
    //   contactData.moreInfo = []; // Initialize as an empty array if it doesn't exist
    // }

    // Push the new data directly into contactData.moreInfo
    // contactData.moreInfo.push(data);

    setIsLoading((prev) => !prev); // Toggle loading back
  };

  const handleClose = () => {
    setOpen(false);
    setEditOpen(false);
    check = [];
    setContactdata({
      ...contactData,
      // profileImg: "",
      // theme: 1,
      prefix: "",
      firstName: "",
      middleName: "",
      lastName: "",
      // suffix: "",
      // accreditations: "",
      // prefferredName: "",
      // maidenName: "",
      // pronouse: "",
      // title: "",
      // department: "",
      company: "",
      phone: "",
      email: "",
      address: "",
      instagram: "",
      linkedin: "",
      twitter: "",
      facebook: "",
      youtube: "",
      // other: "",
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  //----------------------------------------------------------------------------------------------------

  // const handleRemove = (e: any) => {
  //   const remove = filterData.filter((item: any) => item.label !== e.label);
  //   setContactdata({ ...contactData, moreInfo: remove });
  //   setShowSubAlert(false);
  // };

  // const handleAddMoreInput = (keyName: any, e: any, index: any) => {
  //   setAddMoreInfo({ ...addMoreInfo, [keyName]: e.target.value });
  //   if (keyName === "mainInput") {
  //     filterData[index].firstInput = e.target.value;
  //   } else if (keyName === "subInput") {
  //     filterData[index].subInput = e.target.value;
  //   }
  // };

  // Fetch contacts data from API
  // const fetchContacts = async () => {
  //   try {
  //     // First API Call: Fetch basic contact list
  //     const response = await fetch(`${API_BASE_URL}/contacts`, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `${token}`, // Ensure correct format
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch contacts");
  //     }

  //     const contactList = await response.json(); // Assuming response contains contactId
  //     console.log("Contact List:", contactList);

  //     // Extract contact IDs
  //     const contactIds = contactList.map((contact) => contact.contactId);
  //     const detailsPromises = contactIds.map((contactId) =>
  //       fetch(`${API_BASE_URL}/contacts/${contactId}`, {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `${token}`, // Ensure correct format
  //         },
  //       }).then((res) => res.json())
  //     );

  //     const contactDetails = await Promise.all(detailsPromises);
  //     console.log(`contactDetails`, contactDetails);
  //     const combinedData = contactList.map((contact, index) => ({
  //       ...contact,
  //       details: contactDetails[index],
  //     }));

  //     console.log("Combined Data:", combinedData);
  //     setContactCard(combinedData);
  //   } catch (error) {
  //     console.error("Error fetching contacts:", error);
  //     setError(error.message);
  //   }
  // };
  const fetchContacts = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/contacts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch contacts");
      }

      const contactList = await response.json();

      // Check if there are no contacts
      if (!Array.isArray(contactList) || contactList.length === 0) {
        setContactCard([]); // Set empty array
        return; // Exit function early
      }

      // Extract contact IDs
      const contactIds = contactList.map((contact) => contact.contactId);
      const detailsPromises = contactIds.map((contactId) =>
        fetch(`${API_BASE_URL}/contacts/${contactId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }).then((res) => res.json())
      );

      const contactDetails = await Promise.all(detailsPromises);
      const combinedData = contactList.map((contact, index) => ({
        ...contact,
        details: contactDetails[index],
      }));

      setContactCard(combinedData);
      setContactCardData(combinedData);
    } catch (error) {
      console.error("Error fetching contacts:", error);
      setError(error.message);
    }
  };

  // Submit contact data to API
  // const submitContact = async (data: any) => {
  //   try {
  //     const response = await fetch(`${API_BASE_URL}/contacts`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `${token}`,
  //       },
  //       body: JSON.stringify(data), // ✅ Include the contact data in the request
  //     });

  //     const responseData = await response.json(); // ✅ Store the response correctly
  //     console.log(responseData);
  //     if (response.status === 200) {
  //       setContactCard([...contactCard, response]);
  //       setOpen(false);
  //       setShowAlert(false);
  //       setContactdata({
  //         profileImg: "",
  //         theme: 1,
  //         prefix: "",
  //         firstName: "",
  //         middleName: "",
  //         lastName: "",
  //         suffix: "",
  //         accreditations: "",
  //         prefferredName: "",
  //         maidenName: "",
  //         pronouse: "",
  //         title: "",
  //         department: "",
  //         company: "",
  //         headline: "",
  //         moreInfo: [],
  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error submitting contact:", error);
  //     setShowAlert(true);
  //   }
  // };

  // Edit contact data (API call to update the contact)
  // const updateContact = async (id: string, data: any) => {
  //   try {
  //     const response = await axios.put(`${API_BASE_URL}/contact/${id}`, data, {
  //       headers: { Authorization: `Bearer ${token}` }, // Including token in the request header
  //     });
  //     if (response.status === 200) {
  //       const updatedContacts = contactCard.map((contact: any) =>
  //         contact.id === id ? { ...contact, ...data } : contact
  //       );
  //       setContactCard(updatedContacts);
  //       setEdit(false);
  //     }
  //   } catch (error) {
  //     console.error("Error updating contact:", error);
  //     setShowAlert(true);
  //   }
  // };
  const updateContact = async (contactId: string, data: any) => {
    console.log("id --------------> ", `${contactId}`);
    console.log("data --------------> ", data);
    // console.log("Token being sent:", token);
    try {
      const response = await axios.put(
        ` ${API_BASE_URL}/contacts/${contactId}`,
        data,
        {
          headers: { Authorization: `${token}` }, // Including token in the request header
        }
      );
      console.log("response --------------------->", response);
      if (response.status === 200) {
        const updatedContacts = contactCard.map((contact: any) =>
          contact.contactId === contactId ? { ...contact, ...data } : contact
        );
        setContactCard(updatedContacts);
        setOpen(false);
        setEditOpen(false);
        setEdit(false);
      }
    } catch (error) {
      console.error("Error updating contact:", error);
      setShowAlert(true);
    }
  };
  const handleSubmitUpdataData = () => {
    console.log("ContactIndex --------------->", ContactIndex);
    const selectedContact = contactCard[ContactIndex];
    console.log("EditData --------------> ", EditData);
    // console.log("contactId --------------> ", selectedContact.contactId);
    updateContact(selectedContact.contactId, EditData);
    handleClose();
  };
  const handledeleteContact = (index: number) => {
    const selectedContact = contactCard[index];
    console.log(
      "selectedContact.contactid --------------> ",
      selectedContact.contactId
    );
    if (selectedContact?.contactId) {
      deleteContact(selectedContact.contactId);
    } else {
      console.error("Invalid contact selected for deletion");
    }
  };
  const deleteContact = async (contactId: string) => {
    if (!contactId) {
      console.error("No contactId provided for deletion");
      return;
    }

    console.log("Deleting contact with ID:", contactId);
    console.log("URL:", `${API_BASE_URL}/contacts/${contactId}`);

    try {
      const response = await fetch(`${API_BASE_URL}/contacts/${contactId}`, {
        method: "DELETE",
        headers: {
          Authorization: `${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(
          `Failed to delete contact: ${response.status} ${response.statusText}`
        );
      }
      setContactCard((prevContacts) =>
        prevContacts.filter((contact) => contact.contactId !== contactId)
      );

      console.log("Contact deleted successfully");
    } catch (error) {
      console.error("Error deleting contact:", error);
    }
  };

  // const handleSearch = (e: any) => {
  //   setSearchData(e.target.value);
  //   const filterdItem = contactCard.filter((user: any) =>
  //     user?.firstName?.toLowerCase().includes(searchData?.toLowerCase())
  //   );
  //   sewtShowSearchData(filterdItem);
  // };

  // const handleSubmitData = () => {
  //   if (edit) {
  //     const selectedContact = contactCard[selectCard];
  //     updateContact(selectedContact.id, contactData);
  //   } else {
  //     submitContact(contactData);
  //   }
  // };

  useEffect(() => {
    fetchContacts();
  }, [token]); // Refresh data when token is updated

  const [showScanner, setShowScanner] = useState(false);

  const handleScanClick = () => {
    setShowScanner(true); // Show the ScannerPage component
  };

  const handleBackClick = () => {
    setShowScanner(false); // Return to ContactPage
  };

  const handleContactEdit = (item: any, index: any) => {
    setOpen(true);
    setEditOpen(true);
    setContactIndex(index);
    setContactdata({
      ...contactData,

      prefix: item.prefix,
      firstName: item.firstName,
      middleName: item.middleName,
      lastName: item.lastName,
      company: item.company,
      phone: item.phone,
      email: item.email,
      address: item.address,
      instagram: item.instagram,
      linkedin: item.linkedin,
      twitter: item.twitter,
      facebook: item.facebook,
      youtube: item.youtube,
      // other: item.other,
    });
    setEdit(true);
    setSelectCard(index);
  };

  useEffect(() => {
    setShowSearchData(showSearchDatacontactCardData);
  }, [showSearchDatacontactCardData]);
  useEffect(() => {
    setUpdataContactData(contactData);
    // console.log("Updated contactData ------------> ", UpdatacontactData);
    // console.log("SelectCard contactData ------------> ", selectCard);
  }, [contactData]);
  return (
    <Box className="mainLoginBox">
      {/* {!maxWidth890px && (
        <SideBar commonTexts={texts.commonText} openDrawer={openDrawer} />
      )} */}
      <Box className="layout">
        {/* <HeaderBar
          openAddCards={handleDrawerOpen}
          openDrawer={openDrawer}
          handleDrawer={handleDrawer}
          searchInput={searchData}
          commonText={texts.commonText}
        /> */}
        {!showScanner && (
          <AddSearchBar
            getResponseData={fetchContacts}
            setEditData={setEditData}
            setEditOpen={setEditOpen}
            EditOpen={EditOpen}
            UpdatacontactData={UpdatacontactData}
            handleOpen={handleOpen}
            buttonName="Add"
            handleSubmitUpdataData={handleSubmitUpdataData}
            handleClose={handleClose}
            handleScanClick={handleScanClick}
          />
        )}
        <Box>
          {showScanner ? (
            <Box>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleBackClick}
                style={{
                  color: "#e1aa86",
                  marginTop: "20px",
                  marginBottom: "10px",
                  marginLeft: "10px",
                  padding: "10px 25px",
                  fontWeight: "bold",
                  borderColor: "#e1aa86",
                  borderRadius: "8px", // Slight rounding for a modern look
                  boxShadow: "0px 4px 8px rgba(4, 63, 239, 0.2)", // Initial shadow for 3D effect
                  transition: "all 0.3s ease", // Smooth transition
                }}
                onMouseEnter={(e) => {
                  e.target.style.transform = "translateY(-4px)"; // Lift button on hover
                  e.target.style.boxShadow = "0px 8px 16px rgba(0, 0, 0, 0.3)"; // Stronger shadow
                }}
                onMouseLeave={(e) => {
                  e.target.style.transform = "translateY(0px)"; // Reset button position
                  e.target.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.2)"; // Reset shadow
                }}
                onMouseDown={(e) => {
                  e.target.style.transform = "translateY(2px)"; // Push button down on click
                  e.target.style.boxShadow = "0px 2px 6px rgba(0, 0, 0, 0.2)"; // Smaller shadow
                }}
                onMouseUp={(e) => {
                  e.target.style.transform = "translateY(0px)"; // Reset button position
                  e.target.style.boxShadow = "0px 4px 8px rgba(0, 0, 0, 0.2)"; // Reset shadow
                }}
              >
                BACK
              </Button>
              <ScannerPage
                handleBack={handleBackClick}
                getResponseData={fetchContacts}
              />
                        
            </Box>
          ) : contactCard.length === 0 ? (
            // ✅ Render the empty card UI when no contacts exist
            <div className="emptycardBox">
              <img
                src={require("../../Assets/Images/image/emptyCardsImg.png")}
                alt=""
                className="emptycardImg"
              />
              <Typography className="emptycardText">
                {"No cards found"}
              </Typography>
            </div>
          ) : (
            <Box>
              <ContactCards
                contactCard={contactCard}
                handleContactEdit={handleContactEdit}
                showSearchData={showSearchData}
                searchData={searchData}
                handledeleteContact={handledeleteContact}
              />
            </Box>
          )}
        </Box>

        {/* <AddContact
          commonText={texts}
          handleClose={handleClose}
          open={open}
          showAlert={showAlert}
          contactData={contactData}
          handleSubmitData={handleSubmitData}
          handleContactData={handleContactData}
          // handleAddMoreInfo={handleAddMoreInfo}
          // handleRemove={handleRemove}
          // filterData={filterData}
          // handleAddMoreInput={handleAddMoreInput}
          showSubAlert={showSubAlert}
          addMoreInfo={addMoreInfo}
          error={error}
        /> */}
      </Box>
    </Box>
  );
}

export default ContactPage;
